import {SubmitHandler, useForm} from "react-hook-form";
import {ICourses, ICoursesDetail} from "../../../../../../../models/courses";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {CoursesService} from "../../../../../../../services/courses.service";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {useMutation, useQueryClient} from "react-query";
import {Constants} from "../../../../../../../constans/constants";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../../../ui/error/ErrorMessage";
import {LoaderContext} from "../../../../../../../context/LoaderContext";
import Loader from "../../../../../../ui/loader/Loader";
import {ReactComponent as CheckboxUnchecked} from "../../../../../../../assets/images/tests/checkbox-unchecked.svg";
import {ReactComponent as CheckboxChecked} from "../../../../../../../assets/images/tests/checkbox-checked.svg";
import Select from "react-select";

interface ICourseMainInformation {
    course: ICourses
}

const CourseMainInformation = (
    {
        course
    }: ICourseMainInformation
) => {

    const queryClient = useQueryClient()
    const {
        register,
        setValue,
        handleSubmit,
        watch,
        formState: {errors}
    } = useForm<ICourses>()

    const {show, setShow} = useContext(LoaderContext)
    const {courseId} = useParams()
    const [save, setSave] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)
    const [activeBasicEducation, setActiveBasicEducation] = useState<number | null>(null)

    useEffect(() => {
        register("description")
    }, [register])
    const onEditorStateChange = (editorState: string) => {
        setValue("description", editorState);
    };
    const editorContent = watch("description");

    useEffect(() => {
        if (course) {
            setValue('name', course.name)
            setValue('nameProfStandard', course.nameProfStandard)
            setValue('description', course.description)
            setValue('basicEducation', course.basicEducation)
            setActiveBasicEducation(Number(Constants.LIST_BASIC_EDUCATION.find(el => el.label === course.basicEducation)?.value))
            setValue('qualification', course.qualification)
            setValue('qualificationSPO', course.qualificationSPO)
            setValue('isMain', course.isMain)
        }
    }, [course])

    const updateCourse = useMutation(
        (data: ICoursesDetail) => CoursesService.update(course.id, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["course_detail", courseId])
                setShow(false)
                setSave(true)
            },
            onError: (errorUpdate) => {
                setShow(false)
                const e = errorUpdate as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<ICoursesDetail> = async (data) => {
        setShow(true)
        updateCourse.mutate(data)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setSave(false)
        }, 10000)
        return () => clearTimeout(timer)
    }, [save])

    const classNameFieldForm = "flex gap-[18px] py-[20px] border-b-[2px] border-solid border-[#BDBDBD] first:pt-0 last:pb-0 last:border-none max-lg:flex-col"

    const onChange = (data: any) => {
        setValue("basicEducation", data.label)
        setActiveBasicEducation(Number(data.value))
        setValue("qualification", "")
        setValue("qualificationSPO", "")
    }

    return (
        <>
            {show && <Loader/>}
            {error && (<ErrorMessage numberError={error}/>)}
            <form className="flex flex-col gap-[18px] w-[50%] max-2xl:w-[75%] max-lg:w-full"
                  onSubmit={handleSubmit(onSubmit)}>
                <h2 className="leading-[120%] text-[24px] font-bold">Основная информация</h2>
                <div>
                    <div className={classNameFieldForm}>
                        <div className="w-[170px] flex-shrink-0">Название курса</div>
                        <div className="flex flex-col gap-[8px] w-full">
                            <input
                                className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                placeholder="Введите название курса"
                                {...register("name", {required: true})}/>
                            {errors.name && errors.name.type === "required" && (
                                <p className="text-red-600">Название курса обязательно</p>
                            )}
                        </div>
                    </div>
                    <div className={classNameFieldForm}>
                        <div className="w-[170px] flex-shrink-0">Название курса по профстандарту</div>
                        <div className="flex flex-col gap-[8px] w-full">
                            <input
                                className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                placeholder="Введите название курса"
                                {...register("nameProfStandard", {required: true})}/>
                            {errors.nameProfStandard && errors.nameProfStandard.type === "required" && (
                                <p className="text-red-600">Название курса по профстандарту обязательно</p>
                            )}
                        </div>
                    </div>
                    <div className={classNameFieldForm}>
                        <div className="w-[170px] flex-shrink-0">Приветственный текст</div>
                        <ReactQuill className="h-full" theme="snow" modules={Constants.REACT_QUILL_MODULES}
                                    formats={Constants.REACT_QUILL_FORMATS} value={editorContent}
                                    onChange={onEditorStateChange}/>
                    </div>
                    <div className={classNameFieldForm}>
                        <div className="w-[170px] flex-shrink-0">Необходимое базовое образование</div>
                        <div className="flex flex-col gap-[8px] w-full">
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                        ...theme.colors,
                                        primary: "#17AB57",
                                        primary25: "#17AB571a"
                                    }
                                })}
                                placeholder="Выберите базовое образование"
                                menuPlacement="bottom"
                                defaultValue={Constants.LIST_BASIC_EDUCATION.find(el => el.label === course.basicEducation)}
                                onChange={(e) => {
                                    onChange(e)
                                }}
                                options={Constants.LIST_BASIC_EDUCATION}/>
                            <input type="hidden" {...register("basicEducation", {required: true})}/>
                            {errors.basicEducation && errors.basicEducation.type === "required" && (
                                <p className="text-red-600">Базовое образование обязательно</p>
                            )}
                        </div>
                    </div>
                    {course.folderId && course.folderParentName && course.folderParentName === "ПП" && (
                        <>
                            {(activeBasicEducation === 1 || activeBasicEducation === 3) && (
                                <>
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Квалификация на базе ВО</div>
                                        <div className="flex flex-col gap-[8px] w-full">
                                            <input
                                                className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                                placeholder="Квалификация на базе ВО"
                                                {...register("qualification", {required: true})}/>
                                            {errors.qualification && errors.qualification.type === "required" && (
                                                <p className="text-red-600">Квалификация на базе ВО обязательна</p>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {(activeBasicEducation === 2 || activeBasicEducation === 3) && (
                                <>
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Квалификация на базе СПО</div>
                                        <div className="flex flex-col gap-[8px] w-full">
                                            <input
                                                className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                                placeholder="Квалификация на базе СПО"
                                                {...register("qualificationSPO", {required: true})}/>
                                            {errors.qualificationSPO && errors.qualificationSPO.type === "required" && (
                                                <p className="text-red-600">Квалификация на базе СПО обязательна</p>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <div className={classNameFieldForm}>
                        <label className="flex items-center gap-[6px] cursor-pointer w-full">
                            <input className="hidden peer" type="checkbox" {...register("isMain")}/>
                            <CheckboxUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                            <CheckboxChecked className="hidden peer-checked:block flex-shrink-0"/>
                            Основной курс
                        </label>
                    </div>
                </div>
                <div className="flex gap-[18px] justify-end">
                    <div
                        className={`py-[8px] px-[16px] bg-green-500 rounded-[5px] text-white text-[16px] font-medium` + (save ? " block" : " hidden")}>Сохранено
                    </div>
                    <button
                        type="submit"
                        className="py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-white text-[16px] font-medium">
                        Сохранить
                    </button>
                </div>
            </form>
        </>
    )
}

export default CourseMainInformation