import React, {useEffect, useState} from "react";
import {ReactComponent as UsersIcon} from "../../../../../assets/images/menu/users-icon.svg";
import {ReactComponent as ProgressIcon} from "../../../../../assets/images/supervisor/progress.svg";
import {ReactComponent as ArrowSortIcon} from "../../../../../assets/images/global/arrow-sort-icon.svg";
import {ReactComponent as CoursesIcon} from "../../../../../assets/images/folders/courses-icon.svg";
import {IGroupProgressCourse, IGroupProgressUser} from "../../../../../models/groups";
import dayjs from "dayjs";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import Scrollbars from "react-custom-scrollbars";

interface ISupervisorGroupItem {
    user: IGroupProgressUser
    activeSortUser: boolean
}

const SupervisorGroupItem = (
    {
        user,
        activeSortUser
    }: ISupervisorGroupItem
) => {

    const [showProgress, setShowProgress] = useState<boolean>(false)
    const [activeSort, setActiveSort] = useState<boolean>(false)
    const [filteredCourses, setFilteredCourses] = useState<IGroupProgressCourse[]>([])

    useEffect(() => {
        setShowProgress(false)
    }, [activeSortUser])

    useEffect(() => {
        if(user.courses)
            setFilteredCourses(user.courses)
    }, [user.courses])

    const handleShow = () => {
        setShowProgress(!showProgress)
    }

    const handleSort = () => {
        const arrSort = [
            {method: (a: IGroupProgressCourse, b: IGroupProgressCourse) => a.name > b.name ? 1 : -1},
            {method: (a: IGroupProgressCourse, b: IGroupProgressCourse) => a.name < b.name ? 1 : -1}
        ]
        const sorted = filteredCourses.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        setFilteredCourses([...sorted])
        setActiveSort(!activeSort)
    }

    return (
        <div className={`font-medium ` + (showProgress ? "border-solid border-[1px] border-[#BDBDBD]" : "border-b-[1px] border-solid border-[#BDBDBD] last:border-none")}>
            <div className={`px-[18px] py-[16px] flex max-lg:flex-col max-lg:gap-[16px] items-center w-full text-[16px] hover:bg-[#17AB571a] max-lg:p-[12px] ` + (showProgress ? "bg-[#17AB571a]" : "")}>
                <div className="w-[50%] flex items-center gap-[8px] max-lg:w-full">
                    <UsersIcon className="flex-shrink-0" fill="#17AB57"/>
                    <div className="max-lg:flex-col max-lg:flex">
                        {user.lastName} {user.firstName[0]}. {user.fatherName[0]}. <span className="text-[#BDBDBD]">({user.email})</span>
                    </div>
                </div>
                <div className={`w-[10%] max-lg:w-full ` + (user.isOnline ? "text-[#219653]" : "text-[#292B2C]")}>
                    {user.isOnline ? "В сети" : user.lastActivity ? dayjs(user.lastActivity).format("DD.MM.YYYY HH:mm") : ""}
                </div>
                <div className={`w-[40%] max-lg:w-full flex justify-end max-lg:justify-center items-center cursor-pointer ` + (showProgress ? "text-[#17AB57]" : "text-[#2F80ED]")} onClick={handleShow}>
                    Прогресс
                    <ProgressIcon className={showProgress ? ("rotate-180 transition-all") : ("rotate-0 transition-all")} fill={showProgress ? "#17AB57" : "#2F80ED"}/>
                </div>
            </div>
            <div className={`transition-all ${showProgress ? "h-full block" : "h-0 hidden"}`}>
                <div className="py-[12px] px-[18px] max-lg:p-0">
                    <div className="flex text-[#828282] w-full max-lg:hidden">
                        <div className="pl-[36px] cursor-pointer flex gap-[4px] w-[50%]" onClick={handleSort}>
                            Название
                            <ArrowSortIcon
                                className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                        </div>
                        <div className="w-[20%]">Статус</div>
                        <div className="w-[30%] flex justify-end">Когда был завершен</div>
                    </div>
                    <div className="max-h-[300px] overflow-y-auto">
                        {user.courses && user.courses.map((course, index) => (
                            <div key={index}
                                 className="py-[20px] flex items-center border-b-[1px] border-solid border-[#BDBDBD] last:border-none max-lg:p-[12px] max-lg:flex-col max-lg:gap-[12px]">
                                <div className="w-[50%] max-lg:w-full flex gap-[12px] items-center">
                                    <CoursesIcon className="flex-shrink-0"/>
                                    {course.name}
                                </div>
                                <div
                                    className={`w-[20%] max-lg:w-full max-lg:flex max-lg:flex-col gap-[8px] ` + (course.countCompleted === course.countGeneral ? "text-[#219653]" : "text-[#292B2C]")}>
                                    <div className="hidden max-lg:block text-[#828282]">Статус</div>
                                    {course.countCompleted === course.countGeneral ? (
                                        <>Завершен</>
                                    ) : (
                                        <>{course.countCompleted}/{course.countGeneral} завершено</>
                                    )}
                                </div>
                                <div
                                    className="w-[30%] max-lg:w-full flex justify-end max-lg:justify-start max-lg:flex-col gap-[8px]">
                                    <div className="hidden max-lg:block text-[#828282]">Когда был завершен</div>
                                    {course.dateFinish ? dayjs(course.dateFinish).format("DD.MM.YYYY HH:mm") : ""}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SupervisorGroupItem