import {IGroupUserItem} from "../../../../../../models/groups";
import {Link, useLocation} from "react-router-dom";
import React, {useState} from "react";
import {ReactComponent as UsersIcon} from "../../../../../../assets/images/menu/users-icon.svg";
import {ReactComponent as DeleteUser} from "../../../../../../assets/images/global/close-modal-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../assets/images/users/delete-course.svg";
import {ReactComponent as EditCourseIcon} from "../../../../../../assets/images/users/edit-course.svg";
import {IUserCourseUntie} from "../../../../../../models/users";
import {useMutation, useQueryClient} from "react-query";
import {UsersService} from "../../../../../../services/users.service";
import Loader from "../../../../../ui/loader/Loader";
import {AxiosError} from "axios";

interface IGroupsDetailUserItem {
    user: IGroupUserItem
    setUserIdForAddCourse: (id: number) => void,
    groupId: number
}

const GroupsDetailUserItem = (
    {
        user,
        setUserIdForAddCourse,
        groupId
    }: IGroupsDetailUserItem
) => {

    const {pathname} = useLocation()
    const queryClient = useQueryClient()
    const [showLoader, setShowLoader] = useState<boolean>(false)

    const switchRole = (id: number) => {
        switch (id) {
            case 1: return "А";
            case 2: return "М";
            case 3: return "Р";
            default: return "У";
        }
    }

    const untieCourse = useMutation(
        (data: IUserCourseUntie) => UsersService.untieCourse(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["group", groupId])
                setShowLoader(false)
            },
            onError: (error) => {
                console.log(error)
                setShowLoader(false)
            }
        }
    )

    const handleDeleteCourse = (courseId: number) => {
        let untieSuccess = window.confirm("Вы действительно хотите отвязать курс у пользователя? Это действие необратимо")
        if(untieSuccess) {
            setShowLoader(true)
            const data: IUserCourseUntie = {
                "userId": user.id,
                "courseId": courseId
            }
            untieCourse.mutate(data)
        }
    }

    const excludeUser = useMutation(
        () => UsersService.excludeGroup(user.id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["group", groupId])
                setShowLoader(false)
            },
            onError: (error) => {
                console.log(error)
                setShowLoader(false)
                const e = error as AxiosError
                // setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleExcludeUser = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите отвязать пользователя?")
        if(deleteSuccess) {
            setShowLoader(true)
            excludeUser.mutate()
        }
    }

    const handleAddCourse = () => {
        setUserIdForAddCourse(user.id)
    }

    return (
        <>
            {showLoader && (<Loader/>)}
            <div
                className="text-[#292B2C] items-center flex max-lg:flex-col max-lg:gap-[10px] text-[16px] font-medium leading-[120%] border-solid border-b-[2px] border-[#BDBDBD] last:border-none py-[16px] first:pt-0 last:pb-0">
                <div className="flex items-center gap-[8px] w-[35%] max-lg:w-full">
                    <UsersIcon className="flex-shrink-0" fill="#17AB57"/>
                    <Link to={`/users/${user.id}`} state={{group: true, pathname: pathname}} className="max-lg:flex-col max-lg:flex hover:underline">
                        {user.lastName} {user.firstName} {user.fatherName} <span
                        className="text-[#BDBDBD]">({user.email})</span>
                    </Link>
                </div>
                <div className="w-[50%] pr-[30px] max-lg:w-full max-lg:pr-0">
                    {user.courses && (
                        <div className="flex flex-col">
                            {user.courses.map((course, index) => (
                                <div key={index} className="border-b-[#BDBDBD] border-b-[1px] border-b-solid last:border-none py-[8px] flex items-center gap-[8px]">
                                    {course.name} <DeleteUser className="flex-shrink-0 cursor-pointer" onClick={() => handleDeleteCourse(course.id)}/>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="w-[5%] flex justify-center max-lg:w-full max-lg:justify-start">
                    <div className="w-[35px] h-[35px] bg-[#17AB57] flex items-center justify-center text-white">
                        {switchRole(user.role.id)}
                    </div>
                </div>
                <div className="w-[10%] max-lg:w-full flex justify-end gap-[8px]">
                    <div
                        onClick={handleAddCourse}
                        className="rounded-[2px] cursor-pointer bg-[#2F80ED] w-[24px] h-[24px] flex items-center justify-center flex-shrink-0">
                        <EditCourseIcon className="w-[16px] h-[16px]"/>
                    </div>
                    <div
                        onClick={handleExcludeUser}
                        className="rounded-[2px] cursor-pointer bg-[#EB5757] w-[24px] h-[24px] flex items-center justify-center flex-shrink-0">
                        <DeleteIcon className="w-[16px] h-[16px]"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupsDetailUserItem