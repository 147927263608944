import {useMutation, useQuery} from "react-query";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {LessonsService} from "../../../../../services/lessons.service";
import Loader from "../../../../ui/loader/Loader";
import React, {useRef, useState} from "react";
import {IFile} from "../../../../../models/file";
import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import PreviewLessonDocument from "../../../../ui/preview-lesson-document/PreviewLessonDocument";
import PreviewLessonAdditionalFiles from "./lesson-additional-files/PreviewLessonAdditionalFiles";
import ListLessonInLesson from "../../../../ui/list-lesson-in-lesson/ListLessonInLesson";

const PreviewLesson = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [mainFile, setMainFile] = useState<IFile>({} as IFile)
    const [additionalFiles, setAdditionalFiles] = useState<IFile[]>([])
    const [showLessons, setShowLessons] = useState<boolean>(true)
    const navigate = useNavigate()
    const {lessonId} = useParams()
    const refDownloadFile = useRef<HTMLAnchorElement>(null)

    const lesson = useQuery(
        ["lesson_preview", lessonId],
        () => LessonsService.preview(Number(lessonId)),
        {
            enabled: !!lessonId,
            onSuccess: (response) => {
                const arrAdditionalFiles: IFile[] = []
                if(response.files) {
                    response.files.map(lesson => {
                        if (lesson.isMain) {
                            setMainFile(lesson)
                        } else {
                            arrAdditionalFiles.push(lesson)
                        }
                    })
                    setAdditionalFiles(arrAdditionalFiles)
                }
            }
        }
    )

    const downloadFile = useMutation(
        (id: number) => LessonsService.downloadFile(id),
        {
            onSuccess: (response) => {
                if(refDownloadFile.current) {
                    refDownloadFile.current.href = response
                    refDownloadFile.current.click()
                }
            }
        }
    )

    const handleDownloadFile = () => {
        downloadFile.mutate(mainFile.id)
    }

    // const courseId = lesson.data?.courseId
    // const course = useQuery(
    //     ["course_preview", lesson.data?.courseId],
    //     () => CoursesService.getById(String(courseId)),
    //     {
    //         enabled: !!courseId,
    //     }
    // )

    // const lessons = useQuery<IListLessonsByCourse>(
    //     ['lessons_course_preview', Number(courseId)],
    //     () => LessonsService.getByCourse(Number(courseId)),
    //     {
    //         enabled: !!courseId,
    //     }
    // )

    if (lesson.isLoading) return <Loader/>
    // if (course.isLoading) return <Loader/>
    // if (lessons.isLoading) return <Loader/>

    return (
        <div className="flex flex-col gap-[24px]">
            <div className="flex w-full justify-between items-center">
                <div className="text-[36px] text-[#292B2C] font-bold leading-[120%]">Предпросмотр урока</div>
                {!searchParams.get("attach-lesson") && (
                    <div
                        onClick={() => navigate(-1)}
                        className="flex gap-[6px] py-[8px] px-[12px] border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer items-center">
                        <ArrowBackIcon/>
                        Назад
                    </div>
                )}
            </div>
            {lesson.data && (
                <div className="flex gap-[12px] max-2xl:flex-col">
                    <div className="flex gap-[12px] flex-col w-full">
                        <div
                            className="p-[32px] bg-white rounded-[6px] text-[24px] text-[#292B2C] font-bold leading-[120%]">
                            {lesson.data.course.id} - {lesson.data.course.name}
                        </div>
                        <div className="p-[32px] bg-white rounded-[6px] flex gap-[12px] max-lg:flex-col items-center">
                            <div className="text-[24px] text-[#292B2C] font-bold w-full leading-[120%]">
                                {lesson.data.name}
                            </div>
                            {(mainFile.extention === ".youtu" || mainFile.extention === ".link") ? (
                                <Link
                                    className="px-[16px] py-[8px] text-white text-[16px] font-medium bg-[#17AB57] rounded-[5px]"
                                    to={mainFile.pathName}>
                                    Перейти
                                </Link>
                            ) : (
                                <>
                                    <div
                                        onClick={handleDownloadFile}
                                        className="cursor-pointer max-lg:w-full max-lg:text-center py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-[16px] text-[#FFFFFF] font-bold">
                                        Скачать
                                    </div>
                                    <a ref={refDownloadFile} href="#" className="hidden"></a>
                                </>
                            )}
                        </div>
                        <div className="bg-white rounded-[6px] p-[32px]">
                            <PreviewLessonDocument file={mainFile}/>
                        </div>
                    </div>
                    <div className="flex gap-[12px] flex-col flex-shrink-0 w-[470px] max-2xl:w-full">
                        {additionalFiles && additionalFiles.length > 0 && (
                            <PreviewLessonAdditionalFiles files={additionalFiles}/>
                        )}
                        <ListLessonInLesson
                            preview={true}
                            lesson={lesson.data}
                            lessons={lesson.data.course.lessons}
                        />
                        {/*<div className="p-[32px] flex flex-col gap-[24px] rounded-[6px] bg-white max-lg:hidden">*/}
                        {/*    <div className="flex justify-between items-center">*/}
                        {/*        <div className="text-[24px] text-[#292B2C] font-bold">Уроки</div>*/}
                        {/*        <div*/}
                        {/*            onClick={() => setShowLessons(!showLessons)}*/}
                        {/*            className="text-[16px] text-[#BDBDBD] font-medium flex gap-[6px] items-center cursor-pointer">*/}
                        {/*            <Arrow className={`transition-all ` + (showLessons ? " rotate-180" : " rotate-0")} width="18px" height="18px"/>*/}
                        {/*            {showLessons ? "Скрыть" : "Раскрыть"}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*        <div*/}
                        {/*            className={`transition-all` + (showLessons ? " h-[190px]" : " h-0")}>*/}
                        {/*            <ScrollContainer>*/}
                        {/*            {lessons.data.lessons.map((item, index) => (*/}
                        {/*                <div*/}
                        {/*                    key={index}*/}
                        {/*                    className="py-[12px] border-b-[2px] border-solid border-[#BDBDBD] last:border-none flex gap-[12px] items-center mr-[15px]">*/}
                        {/*                    {lesson.data.order > item.order && (*/}
                        {/*                        <MarkPassLessonIcon className="flex-shrink-0"/>*/}
                        {/*                    )}*/}
                        {/*                    {lesson.data.order === item.order && (*/}
                        {/*                        <>*/}
                        {/*                            {lesson.data.testId ? (*/}
                        {/*                                <TestIcon className="flex-shrink-0 fill-[#F21450]"/>*/}
                        {/*                            ) : (*/}
                        {/*                                <LessonIcon className="flex-shrink-0 fill-[#F21450]"/>*/}
                        {/*                            )}*/}
                        {/*                        </>*/}
                        {/*                    )}*/}
                        {/*                    {lesson.data.order < item.order && (*/}
                        {/*                        <>*/}
                        {/*                            {item.testId ? (*/}
                        {/*                                <TestIcon className="flex-shrink-0 fill-[#BDBDBD]"/>*/}
                        {/*                            ) : (*/}
                        {/*                                <LessonIcon className="flex-shrink-0 fill-[#BDBDBD]"/>*/}
                        {/*                            )}*/}
                        {/*                        </>*/}
                        {/*                    )}*/}
                        {/*                    {item.name}*/}
                        {/*                </div>*/}
                        {/*            ))}*/}
                        {/*            </ScrollContainer>*/}
                        {/*        </div>*/}
                        {/*    <div*/}
                        {/*        className="w-full px-[16px] py-[8px] text-white text-[16px] font-medium bg-[#17AB57] rounded-[5px] text-center">*/}
                        {/*        Следующий урок*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            )}
        </div>
    )
}

export default PreviewLesson