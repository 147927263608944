import React, {useEffect, useState} from "react";
import {CoursesService} from "../../../../../services/courses.service";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import CoursesItem from "./couses-item/CoursesItem";
import {ICourses} from "../../../../../models/courses";
import {AxiosError} from "axios";
import {useQuery} from "react-query";

interface List {
    folderId?: string
    modalCourse: ICourses
    parentParameter: DOMRect
    activeSort: boolean
}

const CoursesList = (
    {
        folderId,
        modalCourse,
        parentParameter,
        activeSort
    }: List
) => {

    const [error, setError] = useState<number>(400)
    const [filteredCourses, setFilteredCourses] = useState<ICourses[]>([])
    const [courseCopy, setCourseCopy] = useState<ICourses>({} as ICourses)

    const coursesList = useQuery(
        ["courses_list", folderId],
        () => CoursesService.getAll(folderId ? folderId : ""),
        {
            // enabled: !!folderId,
            keepPreviousData: false,
            onSuccess: (response) => {
                setFilteredCourses(response)
            }
        }
    )

    useEffect(() => {
        setFilteredCourses(prev => [modalCourse, ...prev])
    }, [modalCourse])

    function removeCourse(id: number) {
        setFilteredCourses(filteredCourses.filter(f => f.id !== id))
    }

    useEffect(() => {
        setFilteredCourses(prev => [...prev, courseCopy])
    }, [courseCopy])

    useEffect(() => {
        const arrSort = [
            {method: (a: ICourses, b: ICourses) => a.name < b.name ? 1 : -1},
            {method: (a: ICourses, b: ICourses) => a.name > b.name ? 1 : -1}
        ]
        const sorted = filteredCourses.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        setFilteredCourses([...sorted])
    }, [activeSort])

    if (coursesList.isError) {
        const e = coursesList.error as AxiosError
        setError(e.response ? e.response.status : error)
    }

    return (
        <>
            {coursesList.isError && (<ErrorMessage numberError={error}/>)}
            {filteredCourses.length > 0 && (
                <>
                    {filteredCourses.map(course => (
                        <CoursesItem
                            setError={setError}
                            parentParameter={parentParameter}
                            setCourseCopy={setCourseCopy}
                            removeCourse={removeCourse}
                            course={course}
                            key={course.id}/>)
                    )
                    }
                </>
            )}
        </>
    )
}

export default CoursesList