import {Routes as Router, Route, Navigate, Outlet} from "react-router-dom";
import Home from "./screens/home/Home";
import CourseDetail from "./screens/admin/courses/course-detail/CourseDetail";
import Tests from "./screens/admin/tests/Tests";
import TestsDetail from "./screens/admin/tests/tests-detail/TestsDetail";
import Folders from "./screens/admin/folders/Folders";
import Users from "./screens/admin/users/Users";
import UsersDetail from "./screens/admin/users/users-detail/UsersDetail";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext";
import Login from "./screens/login/Login";
import Groups from "./screens/admin/groups/Groups";
import GroupsDetail from "./screens/admin/groups/groups-detail/GroupsDetail";
import PreviewLesson from "./screens/admin/preview/lesson/PreviewLesson";
import Cookies from "universal-cookie";
import {Constants} from "../constans/constants";
import StudentCourses from "./screens/student/student-courses/StudentCourses";
import StudentCourseListLessons from "./screens/student/student-course-list-lessons/StudentCourseListLessons";
import StudentLessonView from "./screens/student/student-lesson-view/StudentLessonView";
import StudentLessonTestPassing
    from "./screens/student/student-lesson-test/student-lesson-test-passing/StudentLessonTestPassing";
import StudentLessonTestViewResult
    from "./screens/student/student-lesson-test/student-lesson-test-view-result/StudentLessonTestViewResult";
import SupervisorGroup from "./screens/supervisor/supervisor-group/SupervisorGroup";
import UserCourseResult from "./screens/admin/users/users-detail/user-course-result/UserCourseResult";
import Document from "./screens/document/Document";
import Events from "./screens/admin/events/Events";
import PreviewTest from "./screens/admin/preview/test/PreviewTest";
import Company from "./screens/admin/company/Company";
import CompanyDetail from "./screens/admin/company/company-detail/CompanyDetail";
import UsersAttachLessons from "./screens/admin/users/users-attach-lessons/UsersAttachLessons";
import Chats from "./screens/chat/Chats";
import ChatDetail from "./screens/chat/chat-detail/ChatDetail";
import LessonList from "./screens/admin/lessons/LessonList";
import ListCourseArchive from "./screens/admin/list-course-archive/ListCourseArchive";

type Props = {}

const PrivateRoutes = () => {
    const {authenticated} = useContext(AuthContext)

    if (!authenticated) return <Navigate to='/' replace/>

    return <Outlet/>
}

const PrivateRoutesRole = () => {
    // const cookies = new Cookies()
    const {currentRoleId} = useContext(AuthContext)
    // const role = cookies.get("role")

    if(Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) === -1) return <Navigate to='/student-courses' replace/>

    return <Outlet/>
}

const PrivateRoutesRoleAdmin = () => {
    // const cookies = new Cookies()
    const {currentRoleId} = useContext(AuthContext)
    // const role = cookies.get("role")

    if(Constants.ADMIN_ROLE !== Number(currentRoleId)) return <Navigate to='/folders' replace/>

    return <Outlet/>
}

const Routes = (props: Props) => {
    return (
        <Router>
            <Route element={<PrivateRoutes/>}>
                <Route element={<PrivateRoutesRole/>}>
                    <Route element={<PrivateRoutesRoleAdmin/>}>
                        <Route path="/company" element={<Company/>}/>
                        <Route path="/company/:id" element={<CompanyDetail/>}/>
                    </Route>
                    <Route path="/folders" element={<Folders/>}/>
                    <Route path="/folders/:folderId" element={<Folders/>}/>
                    <Route path="/folders/courses/:courseId" element={<CourseDetail/>}/>
                    <Route path="/folders/preview-lesson/:lessonId" element={<PreviewLesson/>}/>
                    <Route path="/folders/preview-test/:lessonId/:testId" element={<PreviewTest/>}/>
                    <Route path="/courses-archive" element={<ListCourseArchive/>}/>
                    <Route path="/tests" element={<Tests/>}/>
                    <Route path="/tests/:id" element={<TestsDetail/>}/>
                    <Route path="/users" element={<Users/>}/>
                    <Route path="/users/add" element={<UsersDetail/>}/>
                    <Route path="/users/:id/course/:courseId" element={<UserCourseResult/>}/>
                    <Route path="/users/:id/course/:courseId/attach-lessons" element={<UsersAttachLessons/>}/>
                    <Route path="/add-course-in-fedkurs/:courseId" element={<UsersAttachLessons/>}/>
                    <Route path="/attach-course-in-group/:courseId/group/:groupId" element={<UsersAttachLessons/>}/>
                    <Route path="/attach-course-in-group/:courseId/group/:groupId/user/:userId" element={<UsersAttachLessons/>}/>
                    <Route path="/users/:userId/:id/lesson/:lessonId/test-view-result/:testId" element={<StudentLessonTestViewResult/>}/>
                    <Route path="/groups" element={<Groups/>}/>
                    <Route path="/groups/:id" element={<GroupsDetail/>}/>
                    <Route path="/events" element={<Events/>}/>
                    <Route path="/messages" element={<Chats/>}/>
                    <Route path="/lessons" element={<LessonList/>}/>
                </Route>
                <Route path="/student-courses" element={<StudentCourses/>}/>
                <Route path="/student-courses/:id" element={<StudentCourseListLessons/>}/>
                <Route path="/student-courses/:id/lesson/:lessonId" element={<StudentLessonView/>}/>
                <Route path="/student-courses/:id/lesson/:lessonId/test-view-result/:testId" element={<StudentLessonTestViewResult/>}/>
                <Route path="/student-courses/:id/lesson/:lessonId/test-passing/:testId" element={<StudentLessonTestPassing/>}/>
                <Route path="/users/:id" element={<UsersDetail/>}/>
                <Route path="/supervisor-group" element={<SupervisorGroup/>}/>
                <Route path="/messages/:id" element={<ChatDetail/>}/>
            </Route>
            <Route path="/" element={<Home/>}/>
            <Route path="/document" element={<Document/>}/>
            <Route path="/login" element={<Login/>}/>
        </Router>
    )
}

export default Routes