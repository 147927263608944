import axios from "axios";
import {IEventLoadMore, IEventsStudentLeft, IEventStudent, IUpdateEventStudent} from "../models/events";
import AuthHeader from "./auth-header";
import {Constants} from "../constans/constants";

export const EventService = {
    async getListEvents (id: number, page: number) {
        const {data} = await axios.get<IEventsStudentLeft>(Constants.LOCATION + `/api/Event/events/${id}/${page}`, {headers: AuthHeader()})
        return data
    },

    async loadMoreListEvents (dataSubmit: IEventLoadMore) {
        const {data} = await axios.post<IEventsStudentLeft>(Constants.LOCATION + `/api/Event/eventsLoadMore`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async updateEvent(dataSubmit: IUpdateEventStudent) {
        const {data} = await axios.post<IEventStudent>(Constants.LOCATION + `/api/Event/events/update/`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async getUnreadEvent(id: number) {
        const {data} = await axios.get<number>(Constants.LOCATION + `/api/Event/events/unread/${id}`, {headers: AuthHeader()})
        return data
    }
}