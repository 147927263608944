import {ITest} from "../../../../models/tests";
import Portal from "../portal/Portal";
import Modal from "../Modal";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close-modal-icon.svg";
import {ReactComponent as SearchIcon} from "../../../../assets/images/global/searcg-icon.svg";
import {ReactComponent as TestsIcon} from "../../../../assets/images/global/tests-icon.svg";
import React, {useState} from "react";
import {TestsService} from "../../../../services/tests.service";
import {useQuery} from "react-query";
import ScrollContainer from "../../scroll-container/ScrollContainer";

interface IModalAttachLessonTest {
    onClose: () => void
    setTest: (test: ITest) => void
}

const ModalAttachLessonTest = (
    {
        onClose,
        setTest
    }: IModalAttachLessonTest
) => {

    const [activeTest, setActiveTest] = useState<ITest>({} as ITest)
    const [errorTest, setErrorTest] = useState<boolean>(false)
    const [search, setSearch] = useState<string>("")

    const testList = useQuery(
        ["tests_list"],
        () => TestsService.getAll(),
        {}
    )

    const handleAttach = () => {
        if (Object.keys(activeTest).length > 0) {
            setTest(activeTest)
            onClose()
        } else {
            setErrorTest(true)
        }
    }

    const handleActiveTest = (test: ITest) => {
        setActiveTest(test)
        setErrorTest(false)
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    if (!testList.data) return <div className="text-center">Нет данных</div>

    return (
        <Portal>
            <Modal>
                <div className="flex flex-col gap-[18px] text-[16px]">
                    <div className="flex justify-between items-center font-bold text-[16px]">
                        Привязать тест
                        <CloseIcon className="cursor-pointer" onClick={onClose}/>
                    </div>
                    <div className="relative">
                        <SearchIcon className="absolute top-[12px] left-[10px]"/>
                        <input
                            type="text"
                            onChange={handleSearch}
                            placeholder="Введите название теста"
                            className="border-solid border-[1px] border-[#BDBDBD] rounded-[5px] py-[8px] pr-[12px] pl-[34px] w-full focus:outline-none focus:border-[#17AB57]"/>
                    </div>
                    <div className="">
                        <div
                            className="rounded-[6px] border-solid border-[1px] border-[#BDBDBD] py-[8px] flex flex-col gap-[4px] h-[210px] ">
                            <ScrollContainer chat={false}>
                                {testList.data.filter((test) => test.name.toLowerCase().includes(search.toLowerCase()) ||
                                    test.id === Number(search))
                                    .map((test, index) => (
                                        <div
                                            onClick={() => handleActiveTest(test)}
                                            key={index}
                                            className={`cursor-pointer flex gap-[6px] py-[5px] px-[10px] items-center text-[16px] hover:underline font-medium` + (activeTest.id === test.id ? " underline bg-[#17AB571a]" : "")}>
                                            <TestsIcon className="flex-shrink-0"/>
                                            {test.name} ({test.id})
                                        </div>
                                    ))}
                            </ScrollContainer>

                        </div>
                        {errorTest && (<p className="text-red-600">Выберите тест</p>)}
                    </div>
                    <div className="flex gap-[12px] justify-end">
                        <button onClick={handleAttach}
                                className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]">
                            Привязать
                        </button>
                        <button
                            className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                            onClick={onClose}>
                            Отмена
                        </button>
                    </div>
                </div>
            </Modal>
        </Portal>
    )
}

export default ModalAttachLessonTest