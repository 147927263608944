import {IEventStudent, IUpdateEventStudent} from "../../../../../models/events";
import dayjs from "dayjs";
import React, {useContext} from "react";
import {useMutation, useQueryClient} from "react-query";
import {EventService} from "../../../../../services/event.service";
import Cookies from "universal-cookie";
import {AuthContext} from "../../../../../context/AuthContext";

interface EventItem {
    item: IEventStudent
}

const EventItem = (
    {
        item
    }: EventItem
) => {

    const cookies = new Cookies
    const queryClient = useQueryClient()
    const markup = {__html: item.text}
    const {currentUserId} = useContext(AuthContext)

    const updateEvent = useMutation(
        (data: IUpdateEventStudent) => EventService.updateEvent(data),
        {
            onSuccess: (response) => {
                item.isViewed = response.isViewed
                queryClient.invalidateQueries(["count_event", currentUserId])
            }
        }
    )

    const handleUpdate = () => {
        if(!item.isViewed) {
            const data: IUpdateEventStudent = {
                "id": item.id
            }
            updateEvent.mutate(data)
        }
    }

    return (
        <div
            onClick={handleUpdate}
            className={`px-[18px] py-[20px] flex justify-between items-center max-lg:p-0 border-b-[2px] border-solid border-[#BDBDBD] 
        last:border-0 max-lg:py-[12px] ` + (item.isViewed ? '' : 'bg-[#17AB571a] cursor-pointer')}>
            <div className="w-[80%] max-lg:w-[100%] event-text" dangerouslySetInnerHTML={markup}/>
            <div className="w-[20%] whitespace-nowrap max-lg:hidden">{dayjs(item.date).format("DD.MM.YYYY HH:mm")}</div>
        </div>
    )
}

export default EventItem