import {Link, useParams} from "react-router-dom";
import Loader from "../../../../ui/loader/Loader";
import {ReactComponent as CloseIcon} from "../../../../../assets/images/global/close-modal-icon.svg";
import React, {useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {CompanyService} from "../../../../../services/company.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import {SubmitHandler, useForm} from "react-hook-form";
import {ICompanyDetail} from "../../../../../models/company";

const CompanyDetail = () => {

    const {id} = useParams()

    const [show, setShow] = useState<boolean>(false)
    const [error, setError] = useState<number|null>(null)
    const [save, setSave] = useState<boolean>(false)

    const queryClient = useQueryClient()

    const {
        setValue,
        handleSubmit,
        formState: {errors},
        register
    } = useForm<ICompanyDetail>()

    const company = useQuery(
        ["company", Number(id)],
        () => CompanyService.getById(Number(id)),
        {
            keepPreviousData: false,
            onSuccess: (response) => {
                setValue("name", response.name)
                setValue("domain", response.domain)
                setValue("port", response.port)
                setValue("server", response.server)
                setValue("mail", response.mail)
                setValue("password", response.password)
                setValue("bucketName", response.bucketName)
            }
        }
    )

    useEffect(() => {
        const timer = setTimeout(() => {
            setSave(false)
        }, 10000)
        return () => clearTimeout(timer)
    }, [save])

    const updateCompany = useMutation(
        (data: ICompanyDetail) => CompanyService.update(Number(id), data),
        {
            onSuccess: () => {
                setShow(false)
                setSave(true)
                queryClient.invalidateQueries(['list_companies'])
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<ICompanyDetail> = async (data) => {
        setShow(true)
        updateCompany.mutate(data)
    }

    if (company.isLoading) return <Loader/>

    if (company.isError) {
        const e = company.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if(!company.data) return <div className="text-center text-red-600">Ошибка данных</div>

    const classNameFieldForm = "flex gap-[18px] py-[20px] border-b-[2px] border-solid border-[#BDBDBD] first:pt-0 last:pb-0 last:border-none max-lg:flex-col"

    return (
        <div className="h-full">
            <div className="flex flex-col gap-[16px] max-lg:gap-[8px] h-full">
                <div className="flex justify-between items-center">
                    <h2 className="font-bold text-[36px] leading-[120%]">{company.data.name}</h2>
                    <Link
                        to={`/company`}
                        className="flex gap-[6px] py-[8px] px-[12px] max-lg:hidden border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer items-center">
                        <CloseIcon width="24px" height="24px"/>
                        Выйти из редактора
                    </Link>
                </div>
                <div
                    className="bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:p-[14px] max-lg:gap-0 overflow-hidden h-full">
                    {/*<ScrollContainer>*/}
                        <div className="h-full">
                            {show && (<Loader/>)}
                            {error && (<ErrorMessage numberError={error}/>)}
                            <form className="flex flex-col gap-[18px] w-[50%] max-2xl:w-[75%] max-lg:w-full" onSubmit={handleSubmit(onSubmit)}>
                                <h2 className="leading-[120%] text-[24px] font-bold">Основная информация</h2>
                                <div>
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Название компании</div>
                                        <input
                                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                            type="text"
                                            placeholder="Введите название компании"
                                            {...register("name", {required: true})}/>
                                        {errors.name && errors.name.type === "required" && (
                                            <p className="text-red-600">Название обязательно</p>
                                        )}
                                    </div>
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Домен</div>
                                        <input
                                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                            type="text"
                                            placeholder="Введите домен"
                                            {...register("domain", {required: true})}/>
                                        {errors.domain && errors.domain.type === "required" && (
                                            <p className="text-red-600">Домен обязателен</p>
                                        )}
                                    </div>
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Порт почты</div>
                                        <input
                                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                            type="number"
                                            placeholder="Введите порт почты"
                                            {...register("port", {required: true})}/>
                                        {errors.port && errors.port.type === "required" && (
                                            <p className="text-red-600">Порт обязателен</p>
                                        )}
                                    </div>
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Сервер почты</div>
                                        <input
                                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                            type="text"
                                            placeholder="Введите сервер почты"
                                            {...register("server", {required: true})}/>
                                        {errors.server && errors.server.type === "required" && (
                                            <p className="text-red-600">Сервер обязателен</p>
                                        )}
                                    </div>
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Почта</div>
                                        <input
                                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                            type="text"
                                            placeholder="Введите почту"
                                            {...register("mail", {required: true})}/>
                                        {errors.mail && errors.mail.type === "required" && (
                                            <p className="text-red-600">Почта обязательна</p>
                                        )}
                                    </div>
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Пароль почты</div>
                                        <input
                                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                            type="text"
                                            placeholder="Введите пароль от почты"
                                            {...register("password", {required: true})}/>
                                        {errors.password && errors.password.type === "required" && (
                                            <p className="text-red-600">Пароль обязателен</p>
                                        )}
                                    </div>
                                    <div className={classNameFieldForm}>
                                        <div className="w-[170px] flex-shrink-0">Название бакета</div>
                                        <input
                                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                                            type="text"
                                            placeholder="Введите название бакета"
                                            {...register("bucketName", {required: true})}/>
                                        {errors.bucketName && errors.bucketName.type === "required" && (
                                            <p className="text-red-600">Название бакета обязательно</p>
                                        )}
                                    </div>
                                </div>
                                <div className="flex gap-[18px] justify-end">
                                    <div
                                        className={`py-[8px] px-[16px] bg-green-500 rounded-[5px] text-white text-[16px] font-medium` + (save ? " block" : " hidden")}>Сохранено
                                    </div>
                                    <button
                                        type="submit"
                                        className="py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-white text-[16px] font-medium">
                                        Сохранить
                                    </button>
                                </div>
                            </form>
                        </div>
                    {/*</ScrollContainer>*/}
                </div>
            </div>
        </div>
    )
}

export default CompanyDetail