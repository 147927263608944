import Cookies from "universal-cookie";

const cookies = new Cookies()

export const logout = () => {
    cookies.remove("token", {path: "/"})
    cookies.remove("role", {path: "/"})
    cookies.remove("user", {path: "/"})
    cookies.remove("refreshToken", {path: "/"})
    cookies.remove("refreshTokenExpiry", {path: "/"})
}