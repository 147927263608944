import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {GroupsService} from "../../../../../services/groups.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import {ReactComponent as ArrowBack} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {ReactComponent as GroupsIcon} from "../../../../../assets/images/menu/groups-icon.svg"
import {ReactComponent as CreateCoursesIcon} from "../../../../../assets/images/folders/create-courses-icon.svg";
import {ReactComponent as SearchIcon} from "../../../../../assets/images/global/searcg-icon.svg";
import {ReactComponent as ArrowSortIcon} from "../../../../../assets/images/global/arrow-sort-icon.svg";
import {ReactComponent as DeleteUser} from "../../../../../assets/images/global/close-modal-icon.svg";
import {ReactComponent as Edit} from "../../../../../assets/images/global/edit-icon.svg";
import UsersItem from "../../users/users-item/UsersItem";
import {IUser} from "../../../../../models/users";
import useToggle from "../../../../../hooks/useToggle";
import ModalRenameGroup from "../../../../ui/modal/modal-rename-group/ModalRenameGroup";
import {IGroup, IGroupDetailId} from "../../../../../models/groups";
import ModalAttachUserGroup from "../../../../ui/modal/modal-attach-user-group/ModalAttachUserGroup";
import Loader from "../../../../ui/loader/Loader";
import ModalAttachCourseUser from "../../../../ui/modal/modal-attach-course-user/ModalAttachCourseUser";
import GroupsDetailUserItem from "./groups-detail-user-item/GroupsDetailUserItem";

const GroupsDetail = () => {


    const {id} = useParams()

    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const [activeSort, setActiveSort] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [groupItem, setGroupItem] = useState<IGroup>({} as IGroup)
    const [isModalUpdateGroup, openIsModalUpdateGroup, closeIsModalUpdateGroup] = useToggle()
    const [isModalAttachUser, setOnModalOpenAttachUser, setOnModalCloseAttachUser] = useToggle()
    const [isModalAttachGroupToCourse, setOnModalOpenAttachGroupToCourse, setOnModalCloseAttachGroupToCourse] = useToggle()
    const [error, setError] = useState<number | null>(null)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [userIdForAddCourse, setUserIdForAddCourse] = useState<number | null>(null)

    const group = useQuery(
        ["group", Number(id)],
        () => GroupsService.getById(Number(id)),
        {
            enabled: !!id,
            onSuccess: (response) => {
                setGroupItem(response)
            }
        }
    )

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const arrSort = [
        (a: IUser, b: IUser) => (a.lastName + " " + a.firstName + " " + a.fatherName) > (b.lastName + " " + b.firstName + " " + b.fatherName) ? 1 : -1,
        (a: IUser, b: IUser) => (a.lastName + " " + a.firstName + " " + a.fatherName) < (b.lastName + " " + b.firstName + " " + b.fatherName) ? 1 : -1
    ]

    const deleteUsers = useMutation(
        (data: IGroupDetailId) => GroupsService.deleteUsers(data),
        {
            onSuccess: (response) => {
                setGroupItem(response)
            },
            onError: (errorDelete) => {
                const e = errorDelete as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    useEffect(() => {
        if(userIdForAddCourse) setOnModalOpenAttachGroupToCourse()
    }, [userIdForAddCourse]);

    if (group.isLoading) return <div className="text-center">Загрузка...</div>

    if (group.isError) {
        const e = group.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!group.data) return <div className="text-center text-red-600">Нет данных</div>

    const handleDelete = () => {
        const data: IGroupDetailId = {
            "id": group.data.id
        }
        deleteUsers.mutate(data)
    }

    return (
        <>
            {showLoader && <Loader/>}
            <div className="flex flex-col gap-[34px] h-full">
                <div className="w-full flex justify-between items-center">
                    <div className="text-[36px] text-[#292B2C] font-bold leading-[120%]">Группа</div>
                    <Link to={`/groups/`}
                          className="px-[16px] py-[12px] flex gap-[8px] items-center border-[1px] border-[#17AB57] border-solid rounded-[5px]">
                        <ArrowBack className="flex-shrink-0"/>
                        Назад
                    </Link>
                </div>
                <div
                    className="w-full flex items-center justify-between p-[32px] bg-white rounded-[6px] max-lg:flex-col max-lg:gap-[12px] max-lg:p-[14px]">
                    <div className="flex gap-[24px] items-center">
                        <GroupsIcon fill="#17AB57"
                                    className="flex-shrink-0 w-[64px] h-[64px] max-lg:w-[48px] max-lg:h-[48px]"/>
                        <div className="flex flex-col gap-[4px]">
                            <div className="text-[36px] text-[#292B2C] font-bold leading-[120%]">
                                {groupItem.name}
                            </div>
                            <div
                                className="text-[16px] text-[#BDBDBD] font-medium">{groupItem.users ? groupItem.users.length : 0} пользователей
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-[12px]">
                        <div
                            onClick={setOnModalOpenAttachGroupToCourse}
                            className="flex gap-[6px] items-center rounded-[5px] border-[1px] border-solid border-[#17AB57] cursor-pointer px-[16px] py-[8px] max-lg:w-full max-lg:justify-center">
                            <Edit className="flex-shrink-0" fill="#000"/>
                            Назначить всем пользователям один курс
                        </div>
                        <div
                            onClick={openIsModalUpdateGroup}
                            className="flex gap-[6px] items-center rounded-[5px] border-[1px] border-solid border-[#17AB57] cursor-pointer px-[16px] py-[8px] max-lg:w-full max-lg:justify-center">
                            <CreateCoursesIcon className="flex-shrink-0" fill="#000"/>
                            Переименовать группу
                        </div>
                    </div>
                </div>
                <div className="flex gap-[24px] flex-col h-full min-h-0">
                    <div className="w-full flex gap-[12px] items-center text-[16px] max-lg:gap-[12px]">
                        <div className="relative w-full">
                            <SearchIcon
                                className={`absolute top-[8px] left-[10px] ` + (blurSearch ? "fill-[#292B2C]" : "")}/>
                            <input
                                onFocus={() => {
                                    setBlurSearch(true)
                                }}
                                onBlur={() => {
                                    setBlurSearch(false)
                                }}
                                onChange={handleSearch}
                                type="text"
                                placeholder="Поиск"
                                className="py-[8px] pl-[36px] pr-[12px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] bg-[#F2F2F2] w-full focus:border-[#17AB57] focus:outline-none"/>
                        </div>
                        <div
                            onClick={setOnModalOpenAttachUser}
                            className="bg-[#17AB57] text-white rounded-[5px] items-center flex gap-[6px] py-[8px] pl-[12px] pr-[16px] font-medium whitespace-nowrap cursor-pointer justify-center">
                            <CreateCoursesIcon className="flex-shrink-0"/>
                            Добавить <span className="max-lg:hidden">пользователя</span>
                        </div>
                    </div>
                    <div
                        className="bg-white p-[32px] rounded-[6px] flex flex-col gap-[18px] text-[16px] font-medium max-lg:px-[14px] max-lg:py-[14px] max-lg:gap-0 overflow-hidden h-full">
                        <div className="flex w-full justify-between">
                            <div className="text-[20px] font-medium text-[#292B2C]">Пользователи<span
                                className="text-[#BDBDBD]">(всего: {groupItem.users ? groupItem.users.length : 0})</span>
                            </div>
                            {groupItem.users && groupItem.users.length > 0 && (
                                <>
                                    <div
                                        onClick={handleDelete}
                                        className="flex gap-[6px] items-center cursor-pointer text-[16px] font-medium text-[#292B2C]">
                                        <DeleteUser className="flex-shrink-0"/>
                                        Удалить всех
                                    </div>
                                    {error && (<ErrorMessage numberError={error}/>)}
                                </>
                            )}
                        </div>
                        <div
                            className="flex justify-between text-[#828282] text-[16px] font-medium max-lg:hidden">
                            <div className="cursor-pointer flex gap-[4px] w-[35%]"
                                 onClick={() => setActiveSort(!activeSort)}
                            >
                                Имя
                                <ArrowSortIcon
                                    className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                            </div>
                            <div className="w-[50%] flex ">Курсы</div>
                            <div className="w-[5%] flex justify-center">Роль</div>
                            <div className="w-[10%] flex justify-end">Действия</div>
                        </div>
                        <div className="h-full">
                            {groupItem.users && groupItem.users
                                .sort(!activeSort ? arrSort[0] : arrSort[1])
                                .filter(u => u.fatherName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    u.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    u.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    u.email.toLowerCase().includes(searchTerm.toLowerCase()))
                                .map((user, index) => (
                                    <GroupsDetailUserItem
                                        groupId={Number(id)}
                                        user={user}
                                        setUserIdForAddCourse={setUserIdForAddCourse}
                                        key={index}/>
                                ))}
                        </div>
                    </div>
                </div>
                {isModalUpdateGroup &&
                    <ModalRenameGroup detail={true} setGroup={setGroupItem} onClose={closeIsModalUpdateGroup}
                                      group={groupItem}/>}
                {isModalAttachUser && <ModalAttachUserGroup
                    group={groupItem}
                    onClose={setOnModalCloseAttachUser}/>}
                {isModalAttachGroupToCourse && <ModalAttachCourseUser
                    group={group.data}
                    setUserIdForAddCourse={setUserIdForAddCourse}
                    idUserGroup={userIdForAddCourse}
                    onClose={setOnModalCloseAttachGroupToCourse}/>}
            </div>
        </>
    )
}

export default GroupsDetail