import {ICourses, ICoursesDetailId} from "../../../../../../models/courses";
import {Link} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ReactComponent as CoursesIcon} from "../../../../../../assets/images/folders/courses-icon.svg";
import {ReactComponent as ActionIcon} from "../../../../../../assets/images/global/action-icon-horizontal.svg";
import {ReactComponent as EditIcon} from "../../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../assets/images/folders/delete-folder-icon.svg";
import {ReactComponent as CopyIcon} from "../../../../../../assets/images/global/copy-icon.svg";
import {ReactComponent as MoveIcon} from "../../../../../../assets/images/global/move-icon.svg";
import ModalRenameCourse from "../../../../../ui/modal/modal-rename-course/ModalRenameCourse";
import ModalMoveCourse from "../../../../../ui/modal/modal-move-course/ModalMoveCourse";
import useToggle from "../../../../../../hooks/useToggle";
import {CoursesService} from "../../../../../../services/courses.service";
import dayjs from "dayjs";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {LoaderContext} from "../../../../../../context/LoaderContext";
import Loader from "../../../../../ui/loader/Loader";

interface CoursesItem {
    course: ICourses
    removeCourse: (id: number) => void
    setCourseCopy: (course: ICourses) => void
    parentParameter: DOMRect
    setError: (error: number) => void
}

const CoursesItem = (
    {
        course,
        removeCourse,
        setCourseCopy,
        parentParameter,
        setError
    }: CoursesItem
) => {

    const {show, setShow} = useContext(LoaderContext)
    const [courseItem, setCourseItem] = useState<ICourses>({} as ICourses)
    const [courseRename, setCourseRename] = useState<ICourses>({} as ICourses)
    const [modalCourse, setModalCourse] = useState<ICourses>({} as ICourses)
    const [isModalRename, setOnModalRename, setOnModalCloseRename] = useToggle()
    const [isModalMoveCourse, setOnModalMoveCourse, setOnModalCloseMoveCourse] = useToggle()
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [bottomMenu, setBottomMenu] = useState<boolean>(true)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setCourseItem(courseRename)
    }, [courseRename])

    useEffect(() => {
        setCourseItem(course)
    }, [course])

    const handleClick = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        if (!showMenu) return
        handleClickRef(ref, handleClick)
    }, [showMenu])

    const handleClickRef = (ref: React.RefObject<HTMLDivElement>, handleSet: () => void) => {
        const handleClick = (e: MouseEvent) => {
            let element = e.target as HTMLDivElement
            if (!ref.current) return
            if (!ref.current.contains(element)) {
                handleSet()
            }
        }

        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }

    useEffect(() => {
        if (ref.current) {
            const hParent = parentParameter.height + parentParameter.y - 32
            const hMenu = ref.current.getBoundingClientRect().y + 15 + 80
            if (hMenu > hParent)
                setBottomMenu(false)
        }
    }, [parentParameter])

    useEffect(() => {
        if (Object.keys(modalCourse).length > 0) {
            setCourseRename(modalCourse)
        }
    }, [modalCourse])

    const handleMove = () => {
        handleClick()
        setOnModalMoveCourse()
    }

    const handleRename = () => {
        handleClick()
        setOnModalRename()
    }

    const copyCourse = useMutation(
        (data:ICoursesDetailId) => CoursesService.copy(data),
        {
            onSuccess: (response) => {
                setShow(false)
                setCourseCopy(response.data)
            },
            onError: (errorCopy) => {
                setShow(false)
                const e = errorCopy as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleCopy = async () => {
        setShow(true)
        handleClick()
        let data = {
            id: course.id,
        }
        copyCourse.mutate(data)
    }

    const changeArchiveCourse = useMutation(
        (data: ICoursesDetailId) => CoursesService.changeArchiveCourse(data),
        {
            onSuccess: () => {
                setShow(false)
                removeCourse(course.id)
            },
            onError: (errorCopy) => {
                setShow(false)
                const e = errorCopy as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleDelete = async () => {
        let chargeSuccess = window.confirm("Вы действительно хотите удалить курс?")
        if(chargeSuccess) {
            setShow(true)
            handleClick()
            let data = {
                id: course.id,
            }
            changeArchiveCourse.mutate(data)
        } else {
            handleClick()
        }
    }

    return (
        <>
            {Object.keys(course).length > 0 && (
                <div
                    className="px-[18px] py-[20px] hover:bg-[#17AB571a] flex justify-between items-center max-lg:p-0 border-b-[2px] border-solid border-[#BDBDBD] last:border-0 max-lg:py-[12px]">
                    {show && <Loader/>}
                    <div className="w-[30%] flex gap-[12px] items-center max-lg:gap-[6px] max-lg:w-[100%]">
                        <CoursesIcon fill={courseItem.isMain ? "#2F80ED" : "#BDBDBD"} className="flex-shrink-0"/>
                        {window.location.host.indexOf("fedkurs") !== -1 ? (
                            <Link
                                to={`/folders/courses/${courseItem.id}`}>{courseItem.name} ({courseItem.hours})</Link>
                        ) : (
                            <Link
                                to={`/folders/courses/${courseItem.id}`}>{courseItem.name} {courseItem.isMain ? `` : `(${courseItem.hours})`}</Link>
                        )}

                    </div>
                    <div
                        className="w-[10%] whitespace-nowrap max-lg:hidden">{dayjs(courseItem.createdDate).format("DD.MM.YYYY HH:mm")}</div>
                    <div
                        className="w-[10%] whitespace-nowrap max-lg:hidden">{dayjs(courseItem.updatedDate).format("DD.MM.YYYY HH:mm")}</div>
                    <div ref={ref} className="w-[6%] flex justify-end relative">
                        <ActionIcon className="flex-shrink-0 cursor-pointer" onClick={handleClick}/>
                        <div
                            className={`absolute right-[10px] bg-white rounded-[6px] overflow-hidden shadow-[0_5px_10px_0_rgba(0,0,0,0.37)] z-[90]` + (showMenu ? " block" : " hidden") + (bottomMenu ? " top-[15px]" : " bottom-[20px]")}>
                            <div
                                onClick={handleRename}
                                className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                                <EditIcon/>
                                Переименовать
                            </div>
                            <div
                                onClick={handleMove}
                                className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                                <MoveIcon/>
                                Переместить
                            </div>
                            <div
                                onClick={handleCopy}
                                className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                                <CopyIcon/>
                                Копировать
                            </div>
                            <div
                                onClick={handleDelete}
                                className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                                <DeleteIcon/>
                                Удалить
                            </div>
                        </div>
                    </div>
                    {isModalRename && <ModalRenameCourse
                        course={course}
                        setModalCourse={setModalCourse}
                        onClose={setOnModalCloseRename}/>}
                    {isModalMoveCourse && <ModalMoveCourse
                        removeCourse={removeCourse}
                        course={course}
                        onClose={setOnModalCloseMoveCourse}/>}
                </div>
            )}
        </>
    )
}

export default CoursesItem