import axios from "axios";
import AuthHeader from "./auth-header";
import {
    ICoursesGroup,
    IGroup, IGroupAttachCourse, IGroupAttachCourseForUser,
    IGroupDetail,
    IGroupDetailId,
    IGroupLeft,
    IGroupProgress,
    IGroupUser
} from "../models/groups";
import {Constants} from "../constans/constants";

export const GroupsService = {
    async getAll() {
        const {data} = await axios.get<IGroup[]>(Constants.LOCATION + '/api/Group', {headers: AuthHeader()})
        return data
    },

    async create(data: IGroupDetail) {
        const response = await axios.post<IGroup>(Constants.LOCATION + '/api/Group/add', data, {headers: AuthHeader()})
        return response.data
    },

    async rename(id: number, data: IGroupDetail) {
        const response = await axios.post<IGroup>(Constants.LOCATION + `/api/Group/rename/${id}`, data, {headers: AuthHeader()})
        return response.data
    },

    async update(id: number, data: IGroupDetail) {
        const response = await axios.post<IGroup>(Constants.LOCATION + `/api/Group/update/${id}`, data, {headers: AuthHeader()})
        return response.data
    },

    async delete(dataDelete: IGroupDetailId) {
        const {data} = await axios.post<boolean>(Constants.LOCATION + '/api/Group/delete', dataDelete, {headers: AuthHeader()})
        return data
    },

    async getById(id: number) {
        const {data} = await axios.get<IGroup>(Constants.LOCATION + `/api/Group/${id}`, {headers: AuthHeader()})
        return data
    },

    async deleteUsers(dataDelete: IGroupDetailId) {
        const {data} = await axios.post<IGroup>(Constants.LOCATION + `/api/Group/deleteUsers`, dataDelete, {headers: AuthHeader()})
        return data
    },

    async attachUser(dataUser: IGroupUser) {
        const {data} = await axios.post<IGroup>(Constants.LOCATION + `/api/Group/attachUser`, dataUser, {headers: AuthHeader()})
        return data
    },

    async getByPage(page: number, search?: string) {
        const {data} = await axios.get<IGroupLeft>(Constants.LOCATION + `/api/Group/page/${page}/${search}`, {headers: AuthHeader()})
        return data
    },

    async getProgress(id: number) {
        const {data} = await axios.get<IGroupProgress>(Constants.LOCATION + `/api/Group/viewProgress/${id}`, {headers: AuthHeader()})
        return data
    },

    async getCourses() {
        const {data} = await axios.get<ICoursesGroup[]>(Constants.LOCATION + `/api/Group/courses`, {headers: AuthHeader()})
        return data
    },

    async updateUserCourse(dataSubmit: IGroupAttachCourse) {
        const {data} = await axios.post<IGroup>(Constants.LOCATION + `/api/Group/updateUserCourse`, dataSubmit, {headers: AuthHeader()})
        return data
    },

    async attachCourseForUserInGroup(dataSubmit: IGroupAttachCourseForUser) {
        const {data} = await axios.post<IGroup>(Constants.LOCATION + `/api/Group/attachCourseForUserInGroup`, dataSubmit, {headers: AuthHeader()})
        return data
    }
}